<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-05">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">웨비나 다시보기</h1>
        <div class="feature-body">
          <p>BMS에서 주최한 웨비나 다시보기를 조회하실 수 있는 서비스입니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">웨비나 & VOD</a></li>
            <li class="breadcrumb">웨비나 다시보기</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->
      <card-and-list
          @getMoreList="getMoreList"
          :isTaSelectable="true"
          @reload="selectParam"
          :is-webinar-vod="true"
      />
    </div>
  </main>
</template>

<script>
import CardAndList from "@/components/common/CardAndList";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WebinarVODList",
  components: {CardAndList},
  async mounted() {
    await this.initWebinarVODList({
      query : {}
    });
  },
  data() {
    return {
      selectQuery:{}
    }
  },
  computed: {
    ...mapGetters({
      page : 'getWebinarVODPage',
      webinarVodList: "getWebinarVODList",
    })
  },
  methods: {
    ...mapActions(['initWebinarVODList', 'fetchWebinarVODList']),
    async selectParam(query) {
      this.selectQuery = query;
      await this.initWebinarVODList({
        query : {
          ...query
        }
      });
    },
    async getMoreList() {
      await this.fetchWebinarVODList({
        query : {
          page : this.page + 1,
          ...this.selectQuery
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
